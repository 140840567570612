import React from 'react';
import { SITE_METADATA } from 'src/constants';
import Layout from 'src/components/Layout/Layout';
import JulyPayLater from 'src/components/Merchant/Campaign/GeneralCashback202210/JulyPayLater';
import SectionCampaignFooter from 'src/components/Merchant/Campaign/TravelsSummerVol1/SectionCampaignFooter';
import JulyPaymentSteps from 'src/components/Merchant/Campaign/GeneralCashback202210/JulyPaymentSteps';
import JulyCondition from 'src/components/Merchant/Campaign/GeneralCashback202210/JulyConditions';
import JulyAppDownload from 'src/components/Merchant/Campaign/GeneralCashback202210/JulyAppDownload';
import JulyWhyPaidy from 'src/components/Merchant/Campaign/GeneralCashback202210/JulyWhyPaidy';
import JulyTopBanner from 'src/components/Merchant/Campaign/GeneralCashback202210/JulyTopBanner';

const GeneralCashBack202210 = () => {
  return (
    <Layout
      SEOProps={{
        title: SITE_METADATA.general_cashback_202210.title,
        description: SITE_METADATA.general_cashback_202210.description,
      }}
      hasNoHeader
      hasNoFooter
    >
      <JulyTopBanner />
      <JulyPayLater />
      <JulyPaymentSteps />
      <JulyCondition />
      <JulyWhyPaidy />
      <JulyAppDownload />
      <SectionCampaignFooter isThemeBlack />
    </Layout>
  );
};

export default GeneralCashBack202210;
